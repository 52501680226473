/* eslint-disable no-nested-ternary */
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from '@material-ui/core';
import ReactQuill from 'react-quill';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useSnackbar } from 'notistack';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getVonqEducationLevels,
  getVonqIndustries,
  getVonqJobFunctions,
  getVonqJobTitles,
  getVonqLocations,
  getVonqSeniority
} from 'utils/vonq';
import { makeStyles } from '@material-ui/core/styles';
import styled from '@emotion/styled';
import { getUniqueKey } from 'redux/slices/notifications';
import { getTranslatedList, SALARY_CHOICES_INR } from '../constants/constants';
import { useFormFieldsByType } from '../helpers/formFields';
import { fieldValidate, formValidate } from '../helpers/formValidation';
import useLocales from '../hooks/useLocales';
import { RootState } from '../redux/store';
import {
  getCandidates,
  getCultureProfiles,
  getCustomers,
  getDegrees,
  getIndustries,
  getJobs,
  getLanguages,
  getLocations,
  getRecruiterAndAdmin,
  getSchools,
  getSkills
} from '../requests/fixtures';
import { ITSKillsProficiencySelect } from './ProficiencyDisplay';
import MButton from './new-designer/button/MButton';

const FormBox = styled(Box)(() => ({
  background:
    'linear-gradient(94.35deg, rgba(255, 255, 255, 0.8) -5.21%, rgba(255, 255, 255, 0.3) 114.12%)',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.08)',
  borderRadius: '20px',
  padding: '40px'
}));
const AutocompleteStyle = styled(Autocomplete)((props: any) => ({
  '.MuiChip-root': {
    background: !props?.eventInterview ? '#9B5FDA' : 'rgba(93, 56, 137, 0.11)',
    borderRadius: '30px',
    padding: '10px 20px',
    height: 'auto',
    fontFamily: 'Rubik',
    fontWeight: 700,
    fontSize: '14px',
    color: !props?.eventInterview ? '#FFFFFF' : '#562C82',

    '& svg': props?.eventInterview && {
      background: 'url(/static/icons/e-remove.svg) no-repeat right center',
      width: 'max-contemt',

      '& g': {
        display: 'none'
      }
    }
  }
}));

const ReactQuillBody = styled(ReactQuill)(() => ({
  '.ql-snow': {
    background: '#FFFFFF',
    border: '1px solid #C9CAD9'
  },
  '.ql-snow:first-of-type': {
    borderRadius: '30px 30px 0 0',
    borderBottom: 0
  },
  '.ql-snow:last-of-type': {
    borderRadius: '0 0 30px 30px',
    borderTop: 0
  }
}));

export const LocationSelect = (props: any) => {
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const searchLocations = async () => {
    setLoading(true);
    if (query) {
      try {
        const res = await getLocations({ q: query });
        setOptions(res.results);
        setLoading(false);
      } catch {
        setOptions([]);
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      searchLocations();
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [query]);

  return (
    <AutocompleteStyle
      fullWidth
      sx={{ background: '#ffffff', borderRadius: '10px', ...(props.sx || {}) }}
      loading={loading}
      loadingText="Enter Something"
      popupIcon={<KeyboardArrowDownIcon style={{ color: '#562C82' }} />}
      multiple={props.multiple}
      options={options}
      getOptionLabel={(option: any) => option?.display_name}
      inputValue={query}
      onInputChange={(event: any, value: string) => setQuery(value)}
      value={props.value}
      onChange={props.onChange}
      getOptionSelected={(option: any, value: any) => option.id === value.id}
      renderInput={(params: any) => (
        <TextField
          {...params}
          placeholder={props.label}
          error={props.error}
          variant="outlined"
        />
      )}
    />
  );
};

export const GroupSelect = (props: any) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState('');

  const customFlat = (arr: any, langFlag = 1) =>
    arr.map((item: any) => ({
      id: item.id,
      name: item.name[langFlag].value
    }));

  const getSelectList = async (
    callback: (query: string) => any,
    isNested = false
  ) => {
    if (!isNested) {
      if (query || props?.withInitalData) {
        setLoading(true);
        let res = await callback(query);

        if (res && res?.results) {
          res =
            props?.type !== 'select-recruiter'
              ? res?.results
              : res?.results?.map((e: any) => ({
                  name: `${e?.first_name} ${e?.last_name}`,
                  label: `${e?.first_name} ${e?.last_name}`,
                  id: e.id
                }));

          if (props?.type === 'select-candidate') {
            res = res?.map((e: any) => ({
              name: e?.name,
              label: e?.name,
              id: e?.id
            }));
          } else if (props?.type === 'select-job') {
            res = res?.map((e: any) => ({
              name: e?.title,
              label: e?.title,
              id: e?.id
            }));
          }
        }
        const response = res || [];
        if (props.type === 'vonq-job-title') {
          const newId = getUniqueKey();
          response.push({
            id: newId,
            name: query
          });
        }
        setOptions(response);
        setLoading(false);
      } else {
        setOptions([]);
      }
    } else {
      setLoading(true);
      let res = await callback(query);
      if (res && res?.results) {
        res = res.results;
      }
      setOptions(customFlat(res || []));
      setLoading(false);
    }
  };

  const defineType = (type: string) => {
    switch (type) {
      case 'vonq-education-level':
        return getSelectList(getVonqEducationLevels, true);

      case 'vonq-seniority':
        return getSelectList(getVonqSeniority, true);

      case 'vonq-industries':
        return getSelectList(getVonqIndustries);

      case 'vonq-locations':
        return getSelectList(getVonqLocations);

      case 'vonq-job-title':
        return getSelectList(getVonqJobTitles);

      case 'vonq-job-function':
        return getSelectList(getVonqJobFunctions);

      case 'select-industries':
        return getSelectList(getIndustries);

      case 'culture_profile':
        return getSelectList(getCultureProfiles);

      case 'select-customers':
        return getSelectList(getCustomers);

      case 'select-language':
        return getSelectList(getLanguages);

      case 'select-skills':
        return getSelectList(getSkills);

      case 'select-schools':
        return getSelectList(getSchools);

      case 'select-degrees':
        return getSelectList(getDegrees);

      case 'select-recruiter':
        return getSelectList(
          !props?.isCandidate ? getRecruiterAndAdmin : () => {}
        );

      case 'select-candidate':
        return getSelectList(!props?.isCandidate ? getCandidates : () => {});

      case 'select-job':
        return getSelectList(!props?.isCandidate ? getJobs : () => {});
    }
    return null;
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      defineType(props.type);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [query]);

  return (
    <>
      {!props?.isPlaceholder && (
        <Typography
          style={{
            fontFamily: 'Rubik',
            color: '#666666',
            marginBottom: '6px',
            fontSize: props?.fontSize,
            textAlign: props?.textAlign
          }}
        >
          {props.label}
        </Typography>
      )}
      <AutocompleteStyle
        eventInterview={props?.eventInterview}
        style={{
          background: 'white',
          borderRadius: '10px'
        }}
        loading={loading}
        loadingText="Enter Something"
        popupIcon={<KeyboardArrowDownIcon style={{ color: '#562C82' }} />}
        fullWidth
        sx={props.sx || {}}
        multiple={props.multiple}
        options={options}
        freeSolo={props.freeSolo}
        getOptionLabel={(option: any) =>
          typeof option === 'string'
            ? option
            : option?.label ||
              option?.name ||
              option?.title ||
              option?.fully_qualified_place_name ||
              ''
        }
        inputValue={query}
        onInputChange={(event: SyntheticEvent<Element, Event>, value: string) =>
          setQuery(value)
        }
        value={props.value || null}
        onChange={props.onChange}
        getOptionSelected={(option: any, value: any) =>
          option?.id === value?.id
        }
        disabled={props.disabled}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={!query && props?.isPlaceholder ? props.label : ''}
            error={props.error}
            variant="outlined"
            style={{ fontFamily: 'Rubik', fontWeight: 700 }}
            InputLabelProps={{ shrink: !props?.isPlaceholder }}
          />
        )}
      />
    </>
  );
};

export const DynamicRadio = (props: any) => (
  <FormControl sx={{ width: '100%', p: 1 }}>
    <FormLabel component="legend">{props.label}</FormLabel>
    <RadioGroup row={props.row} onChange={props.onChange} value={props.value}>
      {props.options.map(
        (el: { value: any; label: string; disabled?: boolean }, i: number) => (
          <FormControlLabel
            key={`${props.label}_${i}`}
            control={<Radio />}
            value={el.value}
            label={el.label}
            disabled={props.disabled || el.disabled}
          />
        )
      )}
    </RadioGroup>
  </FormControl>
);

export const DynamicInput = (props: any) => {
  const { t } = useLocales();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      border: '1px solid #C9CAD9',
      overflow: 'hidden',
      borderRadius: 30,
      backgroundColor: '#FFFFFF',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: 'Rubik',
      color: '#131417'
    },
    date: {
      fontFamily: 'Rubik'
    }
  }));

  const classes = useStyles();

  switch (props.type) {
    case 'description':
      return (
        <Box>
          <Typography
            style={{
              fontFamily: 'Rubik',
              color: '#666666',
              marginBottom: '6px'
            }}
          >
            {props.label}
          </Typography>
          <ReactQuillBody
            value={props.value}
            onChange={props.onChange}
            className={props.className || null}
            modules={{
              toolbar: [
                [{ font: [] }],
                [{ size: ['small', false, 'large', 'huge'] }],
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ align: [] }],
                [{ color: [] }, { background: [] }],
                ['clean']
              ]
            }}
          />
        </Box>
      );
    case 'email':
    case 'url':
    case 'text':
      return (
        <Grid style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            style={{
              fontFamily: 'Rubik',
              color: '#666666',
              marginBottom: '6px'
            }}
          >
            {props.label}
          </Typography>
          <TextField
            InputProps={{ classes, disableUnderline: true }}
            error={props.error}
            type={props.type}
            value={props.value}
            onChange={props.onChange}
            multiline={props.multiline}
            rows={props.rows}
            className={props.className || null}
          />
        </Grid>
      );
    case 'radio':
      return <DynamicRadio {...props} />;
    case 'date':
      return (
        <>
          <Typography
            style={{
              fontFamily: 'Rubik',
              color: '#666666',
              marginBottom: '6px'
            }}
          >
            {props.label}
          </Typography>
          <TextField
            error={props.error}
            type={props.type}
            value={props.value}
            onChange={props.onChange}
            className="dataTextField"
            InputLabelProps={{
              shrink: true
            }}
          />
        </>
      );
    case 'location':
      return (
        <>
          <Typography
            style={{
              fontFamily: 'Rubik',
              color: '#666666',
              marginBottom: '6px'
            }}
          >
            {props.label}
          </Typography>
          <LocationSelect {...props} />
        </>
      );

    case 'vonq-education-level':
    case 'culture_profile':
    case 'vonq-seniority':
    case 'vonq-industries':
    case 'vonq-job-title':
    case 'vonq-job-function':
    case 'vonq-locations':
    case 'select-industries':
    case 'select-customers':
    case 'select-language':
    case 'select-skills':
    case 'select-schools':
    case 'select-files':
    case 'select-degrees':
    case 'select-recruiter':
    case 'select-candidate':
    case 'select-job':
      return <GroupSelect {...props} />;

    case 'select':
      return (
        <>
          <Typography
            style={{
              fontFamily: 'Rubik',
              color: '#666666',
              marginBottom: '6px',
              fontSize: props?.fontSize
            }}
            sx={props.styling && { minHeight: '47px' }}
          >
            {props.label}
          </Typography>
          <AutocompleteStyle
            eventInterview={props?.eventInterview}
            style={{
              background: 'white',
              borderRadius: '10px',
              fontFamily: 'Rubik'
            }}
            fullWidth
            multiple={props.multiple}
            freeSolo={props.freeSolo}
            options={props.options}
            getOptionLabel={(option: any) =>
              props.freeSolo
                ? option
                : option?.label || option?.name || option?.title
            }
            value={
              props.isRemoteJob
                ? props.value
                : props.freeSolo
                ? props.value
                : props.multiple
                ? props.options.filter((el: any) =>
                    props.value.includes(el.value || el.id)
                  )
                : props.options?.find((el: any) => {
                    const value = el.value || el.id;
                    return value === props.value;
                  })
            }
            onChange={props.onChange}
            renderInput={(params: any) => (
              <TextField {...params} error={props.error} variant="outlined" />
            )}
            onInputChange={() => props?.onInputChange && props.onInputChange()}
          />
        </>
      );

    case 'count-select':
      return (
        <>
          <Typography
            style={{
              fontFamily: 'Rubik',
              color: '#666666',
              marginBottom: '6px'
            }}
          >
            {props.label}
          </Typography>
          <Autocomplete
            fullWidth
            options={props.options}
            getOptionLabel={(option: any) =>
              option?.label || option?.name || option?.title
            }
            value={props.value}
            onChange={props.onChange}
            getOptionSelected={(option: any, value: any) =>
              option.id === value.id
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={props.label}
                error={props.error}
                variant="outlined"
              />
            )}
          />
        </>
      );
    case 'checkbox':
      return (
        <FormControlLabel
          sx={{ pl: 1 }}
          control={<Checkbox checked={props.value} onChange={props.onChange} />}
          label={props.label}
        />
      );
    case 'proficiency':
      return (
        <Box sx={{ ml: 1 }}>
          <Box sx={{ mb: 1 }}>
            <Typography color={props.error ? 'error' : 'inherit'}>
              {t('Proficiencies')}
            </Typography>
          </Box>
          <ITSKillsProficiencySelect
            proficiency={props.value}
            onChange={props.onChange}
          />
        </Box>
      );
    case 'search':
      return (
        <Box sx={{ ml: 1 }}>
          <Box sx={{ mb: 1 }}>
            <Typography color={props.error ? 'error' : 'inherit'}>
              {t('Proficiencies')}
            </Typography>
          </Box>
          <ITSKillsProficiencySelect
            proficiency={props.value}
            onChange={props.onChange}
          />
        </Box>
      );

    default:
      return <div>{props.label}</div>;
  }
};

export const DynamicForm = ({
  inputValues,
  onSubmit,
  type,
  title = '',
  candidate,
  isNew,
  registerStep,
  setRegisterStep,
  registerNotice,
  eventInterview,
  FileUploader,
  styling = false
}: any) => {
  if (type !== 'notification-info') {
    return (
      <FormBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
        style={{
          ...(candidate || registerStep
            ? { padding: 0, boxShadow: 'none' }
            : {}),
          paddingBottom: registerStep ? '26px' : '40px'
        }}
      >
        {title && (
          <Typography
            style={{
              fontWeight: 600,
              fontSize: '20px',
              color: '#131417',
              marginBottom: '25px'
            }}
          >
            {title}
          </Typography>
        )}
        <FormControl
          sx={
            registerStep
              ? {
                  '& p': {
                    textAlign: 'justify',
                    fontSize: '14px',
                    textTransform: 'capitalize'
                  },
                  '& input::placeholder': {
                    fontSize: '12px'
                  },
                  '& .MuiGrid-item': {
                    width: '33%'
                  }
                }
              : {}
          }
        >
          <Grid container spacing={2}>
            {inputValues.map((props: any, i: number) => {
              if (
                type == 'add-candidate' ||
                type == 'customer-info' ||
                type == 'account-short-info'
                  ? i >= 0
                  : type === 'company-info' || type === 'company-info-register'
                  ? i < 3
                  : type === 'account-info' ||
                    type === 'vonq-pre-order-campaigns' ||
                    type === 'candidate-preferences-info'
                  ? i < 2
                  : i <= 1
              ) {
                return (
                  <Grid
                    item
                    sx={
                      registerStep
                        ? {
                            paddingLeft: i === 0 ? '16px' : '30px !important'
                          }
                        : type === 'company-info'
                        ? { width: '33%' }
                        : type === 'cms-gallery' ||
                          type === 'cms-offer' ||
                          type === 'welcome-form' ||
                          type === 'social-media-expertises' ||
                          type === 'languages' ||
                          type === 'upload_files' ||
                          type === 'social-media' ||
                          type === 'candidate-pitch' ||
                          type === 'it-skills' ||
                          type === 'activities' ||
                          type === 'certifications'
                        ? { width: '100%' }
                        : type === 'job-post-second-step'
                        ? { width: '100%', marginTop: i > 0 ? '25px' : 0 }
                        : { width: '50%' }
                    }
                    key={props.id}
                  >
                    <DynamicInput
                      {...props}
                      eventInterview={eventInterview}
                      styling={styling}
                    />
                    {props.helperText ? (
                      <FormHelperText sx={{ mb: 1 }}>
                        {props.helperText}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                );
              }
              return () => {};
            })}
          </Grid>
          <Grid
            container
            spacing={2}
            style={{ marginTop: type === 'job-post-second-step' ? 0 : '25px' }}
          >
            {inputValues.map((props: any, i: number) => {
              if (
                type !== 'company-info-register' &&
                type !== 'add-candidate' &&
                type !== 'customer-info' &&
                type !== 'account-short-info' &&
                (type === 'company-info' || type === 'upload_files'
                  ? i >= 3 && i < 6
                  : type === 'account-info' ||
                    type === 'vonq-pre-order-campaigns' ||
                    type === 'candidate-preferences-info'
                  ? type === 'certifications'
                    ? i > 0 && i < 2
                    : i >= 2 && i < 4
                  : i > 1 && i <= 3)
              ) {
                return (
                  <Grid
                    item
                    sx={
                      type === 'company-info'
                        ? {
                            width: '33%',
                            paddingLeft:
                              registerStep && i !== 3
                                ? '30px !important'
                                : '16px'
                          }
                        : type === 'cms-gallery' ||
                          type === 'cms-offer' ||
                          type === 'welcome-form' ||
                          type === 'social-media-expertises' ||
                          type === 'languages' ||
                          type === 'social-media' ||
                          type === 'it-skills' ||
                          type === 'activities' ||
                          type === 'certifications'
                        ? { width: '100%' }
                        : type === 'job-post-second-step'
                        ? { width: '100%', marginTop: '25px' }
                        : { width: '50%' }
                    }
                    key={props.id}
                  >
                    <DynamicInput {...props} />
                    {props.helperText ? (
                      <FormHelperText sx={{ mb: 1 }}>
                        {props.helperText}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                );
              }
              return () => {};
            })}
          </Grid>
          <Grid
            container
            spacing={2}
            style={
              type === 'job-post-second-step'
                ? {
                    alignItems: 'center',
                    marginTop: '25px'
                  }
                : { marginTop: '25px' }
            }
          >
            {inputValues.map((props: any, i: number) => {
              if (
                type !== 'add-candidate' &&
                type !== 'customer-info' &&
                type !== 'account-short-info' &&
                (type === 'company-info'
                  ? i > 5 && i < 9
                  : type === 'account-info' ||
                    type === 'vonq-pre-order-campaigns' ||
                    type === 'candidate-preferences-info'
                  ? type === 'certifications'
                    ? i > 1 && i < 3
                    : i >= 4
                  : i > 3 && i <= 7)
              ) {
                return (
                  <Grid
                    item
                    sx={
                      type === 'company-info'
                        ? {
                            width: '33%',
                            paddingLeft:
                              registerStep && i !== 6
                                ? '30px !important'
                                : '16px'
                          }
                        : type === 'job-post-second-step' ||
                          type === 'activities'
                        ? {
                            width: '100%',
                            maxWidth: i === 3 ? '100%' : '30%',
                            marginTop: i === 5 ? '25px' : 0
                          }
                        : { width: '50%' }
                    }
                    key={props.id}
                  >
                    <DynamicInput {...props} />
                    {props.helperText ? (
                      <FormHelperText sx={{ mb: 1 }}>
                        {props.helperText}
                      </FormHelperText>
                    ) : null}
                  </Grid>
                );
              }
              return () => {};
            })}
          </Grid>
          {type !== 'add-candidate' &&
            type !== 'customer-info' &&
            type !== 'candidate-pitch' &&
            type != 'it-skills' &&
            type != 'certifications' &&
            type != 'social-media' &&
            type !== 'upload_files' &&
            type !== 'vonq-pre-order-campaigns' &&
            type !== 'account-short-info' &&
            type !== 'candidate-preferences-info' && (
              <>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: '25px', justifyContent: 'space-between' }}
                >
                  {inputValues.map((props: any, i: number) => {
                    if (
                      (i > 7 && i <= 10 && type !== 'company-info') ||
                      (type === 'company-info' && i >= 9 && i < 10)
                    ) {
                      return (
                        <Grid
                          item
                          sx={{
                            minWidth: '33%'
                          }}
                          key={props.id}
                        >
                          <DynamicInput {...props} />
                          {props.helperText ? (
                            <FormHelperText sx={{ mb: 1 }}>
                              {props.helperText}
                            </FormHelperText>
                          ) : null}
                        </Grid>
                      );
                    }
                    return () => {};
                  })}
                </Grid>
                {!registerStep && (
                  <Grid
                    container
                    spacing={2}
                    style={{ marginTop: '20px', alignItems: 'flex-end' }}
                  >
                    {inputValues.map((props: any, i: number) => {
                      if (
                        (type !== 'add-candidate' || 'customer-info') &&
                        i > 10
                      ) {
                        return (
                          <Grid
                            item
                            sx={{
                              minWidth: '33%'
                            }}
                            key={props.id}
                          >
                            <DynamicInput {...props} />
                            {props.helperText ? (
                              <FormHelperText sx={{ mb: 1 }}>
                                {props.helperText}
                              </FormHelperText>
                            ) : null}
                          </Grid>
                        );
                      }
                      return () => {};
                    })}
                  </Grid>
                )}
              </>
            )}
        </FormControl>

        {onSubmit ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: registerStep ? 'space-between' : 'center',
              marginTop: '10px'
            }}
          >
            {registerStep && (
              <MButton
                title="Back"
                MyClass="violetButton"
                click={() => {
                  setRegisterStep && setRegisterStep(registerStep - 1);
                }}
                style={{
                  padding: '11px 110px',
                  width: 'auto',
                  height: 'auto',
                  border: '1.5px solid var(--Purple, #562C82)',
                  background: 'unset',
                  color:
                    'linear-gradient(92.25deg, #9B5FDA -2.57%, #562C82 113.38%)'
                }}
              />
            )}
            {FileUploader && FileUploader()}
            <MButton
              title={registerStep ? 'Next' : 'Save'}
              MyClass="violetButton"
              style={
                registerStep
                  ? {
                      padding: '11px 110px',
                      width: 'auto',
                      height: 'auto'
                    }
                  : {}
              }
              click={onSubmit}
            />
          </Box>
        ) : null}
      </FormBox>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <FormControl>
        <Grid container spacing={2}>
          {inputValues.map((props: any) => (
            <Grid item sx={{ width: '100%' }} key={props.id}>
              <DynamicInput {...props} />
              {props.helperText ? (
                <FormHelperText sx={{ mb: 1 }}>
                  {props.helperText}
                </FormHelperText>
              ) : null}
            </Grid>
          ))}
        </Grid>
      </FormControl>

      {onSubmit ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', marginTop: '31px' }}
        >
          <MButton
            title="Save"
            MyClass="violetButton"
            click={onSubmit}
            style={{
              width: '100%',
              height: registerNotice && '45px',
              fontSize: registerNotice ? '16px' : '14px'
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export const DynamicFormWrapper = ({
  title = '',
  type,
  itemValues,
  FileUploader,
  setValues,
  onSubmit,
  cols,
  candidate = false,
  withOutLang = false,
  registerStep,
  setRegisterStep,
  registerNotice
}: {
  title?: string;
  type: string;
  itemValues: any;
  FileUploader?: any;
  setValues: (field: string, value: any) => void;
  onSubmit: any;
  cols?: boolean;
  candidate?: boolean;
  withOutLang?: boolean;
  registerStep?: number;
  setRegisterStep?: (e: number) => void;
  registerNotice?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState<any>({});
  const opt: any = useSelector((state: RootState) => state.fixturesReducer);
  const { company }: any = useSelector(
    (state: RootState) => state.generalRecruiter
  );
  const options = { ...opt };
  const { DEFAULT_MESSAGE_TEXTS } = getTranslatedList();

  const setNewValues = (field: string, value: any) => {
    setValues(field, value);
    if (requiredFields.includes(field)) {
      const fieldIsEmpty = fieldValidate(value);
      if (fieldIsEmpty) {
        setErrors({
          ...errors,
          [field]: true
        });
      }
      if (!fieldIsEmpty && errors[field]) {
        const errorUpdate: any = {
          ...errors
        };
        delete errorUpdate[field];
        setErrors(errorUpdate);
      }
    }
  };

  const formFields = useFormFieldsByType(
    type,
    itemValues,
    setNewValues,
    options,
    errors,
    '',
    '',
    type === 'candidate-preferences-info' ? company.currency : '',
    false,
    withOutLang
  );

  const requiredFields = formFields
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .filter((field: any) => !field.unRequired)
    .map((field: any) => field.name);

  const handleSubmit = async () => {
    if (formValidate(itemValues, errorHandler, requiredFields)) {
      onSubmit();
    }
  };

  if (type === 'candidate-preferences-info') {
    if (company.currency == 'INR') {
      options.salaries = SALARY_CHOICES_INR;
    }
  }

  const errorHandler = (errors: any) => {
    setErrors(errors);

    if (Object.keys(errors).length) {
      enqueueSnackbar(DEFAULT_MESSAGE_TEXTS.required, {
        variant: 'warning'
      });
    }
  };

  return (
    <DynamicForm
      title={title}
      type={type}
      cols={cols}
      inputValues={formFields}
      FileUploader={FileUploader}
      fullWidth
      onSubmit={handleSubmit}
      candidate={candidate}
      registerStep={registerStep}
      setRegisterStep={setRegisterStep}
      registerNotice={registerNotice}
    />
  );
};
