import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { getButtons } from '../constants/text/buttons';
import useLocales from '../hooks/useLocales';

const DialogBody = styled(Dialog)(() => ({
  '.MuiPaper-root': {
    padding: '80px',
    textAlign: 'center',
    alignItems: 'center'
  }
}));

const ButtonStyle = styled(Button)(() => ({
  borderRadius: '30px',
  minWidth: '130px',
  padding: '10px 0'
}));

const DialogTitleStyle = styled(DialogTitle)(() => ({
  fontWeight: 600,
  fontSize: '20px',
  color: '#131417'
}));

export const DeleteDialog = ({ open, title, onClose, onDelete }: any) => {
  const { BACK, DELETE } = getButtons();
  const { t } = useLocales();

  return (
    <DialogBody
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleStyle id="alert-dialog-title">
        {title || t('Are you sure you want to delete this?')}
      </DialogTitleStyle>
      <DialogActions>
        <ButtonStyle onClick={onClose} color="primary" variant="contained">
          {BACK}
        </ButtonStyle>
        <ButtonStyle onClick={onDelete} color="error" variant="contained">
          {DELETE}
        </ButtonStyle>
      </DialogActions>
    </DialogBody>
  );
};

export const ApproveDialog = ({
  open,
  title = '',
  onClose,
  onApprove,
  profileHeader = false
}: any) => {
  const { BACK, OK } = getButtons();
  const { t } = useLocales();

  return (
    <DialogBody
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitleStyle id="alert-dialog-title">
        {title || t('Are you sure?')}
      </DialogTitleStyle>
      <DialogActions>
        {onClose && (
          <ButtonStyle onClick={onClose} color="primary" variant="outlined">
            {!profileHeader ? BACK : t('No')}
          </ButtonStyle>
        )}
        <ButtonStyle onClick={onApprove} color="primary" variant="contained">
          {!profileHeader ? OK : t('Yes')}
        </ButtonStyle>
      </DialogActions>
    </DialogBody>
  );
};
