import { Button, Card, CardContent, Typography } from '@material-ui/core';
import styled from '@emotion/styled';

const MainContainer = styled('div')(() => ({
  width: '100%',
  height: '80%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
}));

const CultureProfileBanner = (props: { onClose: () => void }) => {
  const { onClose } = props;
  return (
    <MainContainer>
      <Card className="culture-profile-banner-card">
        <CardContent>
          <Typography variant="h5">
            Culture Profile is empty. To continue using the system, please fill
            your culture profile
          </Typography>
          <Button variant="contained" onClick={onClose}>
            Create culture profile
          </Button>
        </CardContent>
      </Card>
    </MainContainer>
  );
};

export default CultureProfileBanner;
